<template>
  <div class="contact-container">
    <h1>Contact Us</h1>
    <div class="contact-content">
        <form @submit.prevent="submitContactForm">
          <input v-model="form.name" type="text" placeholder="Your Name" required />
          <input v-model="form.email" type="email" placeholder="Your Email" required />
          <textarea v-model="form.message" placeholder="Your Message" required></textarea>
          <button type="submit">Submit</button>
        </form>
    </div>
  </div>
  <div class="contact-info">
  <p>TEL: 13237191757</p>
  <p>Wechat: zh3245223251</p>
  <p>E-mail: 3245223251@qq.com</p>
  <p>Address: SHENZHEN 广东 CN</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  methods: {
    submitContactForm() {
      axios.post('http://localhost:5000/api/contact', this.form)
        .then(response => {
          alert(response.data.message);
        })
        .catch(error => {
          console.error("There was an error submitting the contact form:", error);
        });
    }
  }
}
</script>

<style scoped>
.contact-container {
  position: relative; /* 使 contact-info 绝对定位相对于这个容器 */
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 400px; /* 根据需要调整容器的最小高度 */
}

.contact-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  position: relative; /* 为了确保 contact-info 能正确定位 */

}

form {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 20px;
}

form input, form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1em;
}

.contact-info {
  position: absolute; /* 使这个div能够被定位 */
  right: 10px; /* 右对齐 */
  bottom: 100px; /* 底部对齐 */
  flex-basis: 250px;
  border: 2px solid white; /* 黑色框 */
  padding: 10px;
  background-color: white; /* 背景色，确保文字清晰可见 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-info p {
  margin: 10px 0;
  font-size: 1.1em;
}
h1 {
  margin-top: 50px;
}
.contact-container {
  padding: 20px;
  background-image: url(''); /* 替换为你的图片路径 */
  background-size: cover; /* 使背景图片覆盖整个容器 */
  background-position: center; /* 将图片居中显示 */
  background-repeat: no-repeat; /* 防止图片重复 */
  min-height: 80vh; /* 使容器至少覆盖整个视口高度 */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 将内容对齐到左侧 */
  justify-content: flex-start; /* 将内容对齐到顶部 */
  color: black; /* 使文字在背景上清晰可见 */
  box-sizing: border-box; /* 确保padding不会影响高度计算 */
}
</style>


