<!-- CEOPage.vue -->
<template>
  <div>
    <h1>Samual Zou</h1>
    <p>Details about the CEO.</p>
  </div>
</template>

<script>
export default {
  name: 'CEOPage'
}
</script>