<template>
  <header>
    <h1>FutureX Company</h1>
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped>
header {
  background-color: #f8f8f8;
  padding: 10px;
  text-align: center;
}
</style>
