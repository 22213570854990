<template>
  <div class="background-container">
    <h1>{{ companyInfo.name }}</h1>
    <p>{{ companyInfo.description }}</p>
    <p class="hello-text">hello ! welcome to a new world</p> <!-- 为 hello 添加类 -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      companyInfo: {}
    }
  },
  created() {
    axios.get('http://localhost:5000/api/company_info')
      .then(response => {
        this.companyInfo = response.data;
      })
      .catch(error => {
        console.error("Error fetching company info:", error);
      });
  }
}
</script>

<style scoped>
.background-container {
  padding: 20px;
  background-image: url(''); /* 替换为你的图片路径 */
  background-size: cover; /* 使背景图片覆盖整个容器 */
  background-position: center; /* 将图片居中显示 */
  background-repeat: no-repeat; /* 防止图片重复 */
  min-height: 80vh; /* 使容器至少覆盖整个视口高度 */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 将内容对齐到左侧 */
  justify-content: flex-start; /* 将内容对齐到顶部 */
  color: black; /* 使文字在背景上清晰可见 */
  box-sizing: border-box; /* 确保padding不会影响高度计算 */
}

h1 {
  font-size: 5.5em;
  margin-top: 50px;
}

.hello-text {
  font-size: 3em; /* 设置hello文本的字体大小 */
  font-weight: bold; /* 让文字加粗 */
  position: relative; /* 使用绝对定位 */
  top: 50%; /* 使其垂直居中 */
  left: 50%; /* 使其水平居中 */
  transform: translate(-50%, -50%); /* 通过平移实现精确居中 */
  text-align: center; /* 如果内容是多行文本，确保居中 */
}
</style>

