import { createRouter, createWebHistory } from 'vue-router';
import AppHome from '../views/AppHome.vue';
import AppAbout from '../views/AppAbout.vue';
import AppServices from '../views/AppServices.vue';
import AppTeam from '../views/AppTeam.vue';
import AppContact from '../views/AppContact.vue';
//import AppFamousUrls from '../views/AppFamousUrls.vue';

import CEOPage from '@/views/roles/CEOPage.vue';
import AssicatedCEOPage from '@/views/roles/AssicatedCEOPage.vue';
import CTOPage from '@/views/roles/CTOPage.vue';
import CMOPage from '@/views/roles/CMOPage.vue';
import CFOPage from '@/views/roles/CFOPage.vue';
import CHOPage from '@/views/roles/CHOPage.vue';
import COOPage from '@/views/roles/COOPage.vue';

const routes = [
  { path: '/', component: AppHome },
  { path: '/about', component: AppAbout },
  { path: '/services', component: AppServices },
  { path: '/team', component: AppTeam },
  { path: '/contact', component: AppContact },

  { path: '/ceo', name: 'CEO', component: CEOPage },
  { path: '/assicated-ceo', name: 'AssicatedCEO', component: AssicatedCEOPage },
  { path: '/cto', name: 'CTO', component: CTOPage },
  { path: '/cmo', name: 'CMO', component: CMOPage },
  { path: '/cfo', name: 'CFO', component: CFOPage },
  { path: '/cho', name: 'CHO', component: CHOPage },
  { path: '/coo', name: 'COO', component: COOPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

