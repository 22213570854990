<template>
  <footer>
    <p>All rights preserved by FutureX &copy;
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024308116号</a>
      2024
    </p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
footer {
  background-color: #f8f8f8;
  padding: 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

a {
  color: inherit; /* 保持与其他文本一致的颜色 */
  text-decoration: none; /* 去掉链接下划线 */
}

a:hover {
  text-decoration: underline; /* 鼠标悬停时显示下划线 */
}
</style>
