<template>
  <div id="app">
    <Header />
    <nav class="main-nav">
      <router-link to="/" exact>Home</router-link>
      <router-link to="/About" exact>About</router-link>
      <router-link to="/Services">Services</router-link>
      <router-link to="/Team">Team</router-link>
      <router-link to="/Contact">Contact</router-link>
      <!-- 继续添加其他导航链接 -->
    </nav>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from './components/AppHeader.vue';
import Footer from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-nav {
  background-color: #000;
  padding: 10px;
  text-align: center;
}

.main-nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-size: 1.2em;
}

.main-nav a:hover {
  text-decoration: underline;
}

router-view {
  flex: 1; /* 使内容区域撑满页面 */
  padding: 20px; /* 可选：为内容区域添加内边距 */
}

footer {
  background-color: #f8f8f8;
  padding: 10px;
  text-align: center;
}
</style>
