<template>
  <div class="background-container">
    <h1>About Us</h1>
    <p>We are a company focused on technoly to change the world !...</p>
  </div>
</template>

<style scoped>
.background-container {
  padding: 20px;
  background-image: url(''); /* 替换为你的图片路径 */
  background-size: cover; /* 使背景图片覆盖整个容器 */
  background-position: center; /* 将图片居中显示 */
  background-repeat: no-repeat; /* 防止图片重复 */
  min-height: 80vh; /* 使容器至少覆盖整个视口高度 */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 将内容对齐到左侧 */
  justify-content: flex-start; /* 将内容对齐到顶部 */
  color: black; /* 使文字在背景上清晰可见 */
  box-sizing: border-box; /* 确保padding不会影响高度计算 */
}
h1 {
  margin-top: 10px;
}
</style>
