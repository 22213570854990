<template>
  <div class="team-member">
    <h3>{{ name }}</h3>
    <p>{{ role }}</p>
  </div>
</template>

<script>
export default {
  name: 'TeamMember',
  props: {
    name: {
      type: String,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.team-member {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
}
</style>
