<template>
  <div class="background-container">
    <h1>Meet Our Team</h1>
    <div class="team">
      <router-link :to="{ name: 'CEO' }">
        <TeamMember name="Samual Zou" role="CEO"/>
      </router-link>

    </div>
  </div>
</template>

<script>
import TeamMember from '@/components/AppTeamMember.vue';

export default {
  components: {
    TeamMember
  }
}
</script>

<style scoped>
h1 {
  margin-top: 50px;
}
.team {
  display: flex;
  flex-wrap: wrap;
}
.team a {
  text-decoration: none;
  color: black;
}
.background-container {
  padding: 20px;
  background-image: url(''); /* 替换为你的图片路径 */
  background-size: cover; /* 使背景图片覆盖整个容器 */
  background-position: center; /* 将图片居中显示 */
  background-repeat: no-repeat; /* 防止图片重复 */
  min-height: 80vh; /* 使容器至少覆盖整个视口高度 */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 将内容对齐到左侧 */
  justify-content: flex-start; /* 将内容对齐到顶部 */
  color: black; /* 使文字在背景上清晰可见 */
  box-sizing: border-box; /* 确保padding不会影响高度计算 */
}
</style>
